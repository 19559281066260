import React, { useEffect, useRef, useState } from 'react';
import './contact.css';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

const Contact = () => {
  const mapContainerRef = useRef(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    // URL-Fragment (Hash) auslesen
    const hash = window.location.hash;
    if (hash.includes("success=1")) {
      setMessage({ type: "success", text: "✅ Ihre Nachricht wurde erfolgreich gesendet!" });
    } else if (hash.includes("error=1")) {
      setMessage({ type: "error", text: "❌ Es gab ein Problem beim Senden der Nachricht. Bitte versuchen Sie es später erneut." });
    }
  }, []);

  useEffect(() => {
    if (!mapContainerRef.current) return;

    const map = new maplibregl.Map({
      container: mapContainerRef.current,
      style: 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json',
      center: [9.70093, 50.87282],
      zoom: 14
    });

    return () => map.remove();
  }, []);

  return (
    <div className="contact-page" id="contactus">
      <div className="contact-wrapper">

        {/* Kontaktformular */}
        <div className="contact-container">
          <h1 className="contact-title">Lassen Sie Sich Kostenlos Beraten</h1>

          {/* Erfolgsmeldung sichtbar machen */}
          {message && (
            <div className={`alert ${message.type === "success" ? "alert-success" : "alert-danger"}`}>
              {message.text}
            </div>
          )}

          <div className="contact-info">
            <div className="contact-info-item">
              <FaMapMarkerAlt className="contact-icon" />
              <p><strong>Dudenstraße 6</strong>, 36251 Bad Hersfeld</p>
            </div>
            <div className="contact-info-item">
              <FaPhoneAlt className="contact-icon" />
              <p><strong>+49 6621 4374980</strong></p>
            </div>
            <div className="contact-info-item">
              <FaEnvelope className="contact-icon" />
              <p><strong>webdesign@exabyte-systems.com</strong></p>
            </div>
          </div>

          <div className="contact-form-container">
            <form action="/contact.php" method="POST" className="contact-form">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" className="form-input" required />

              <label htmlFor="email">E-Mail</label>
              <input type="email" id="email" name="email" className="form-input" required />

              <label htmlFor="message">Nachricht</label>
              <textarea id="message" name="message" className="form-input" required></textarea>

              <div className="form-check">
                <input type="checkbox" className="form-check-input" id="checkbox" name="checkbox" required />
                <label htmlFor="checkbox" className="form-check-label">
                  Ich habe die Datenschutzerklärung gelesen und akzeptiere sie.
                </label>
              </div>

              <button type="submit" className="btn-submit">Absenden</button>
            </form>
          </div>
        </div>

        {/* Warum uns kontaktieren & Map */}
        <div className="contact-text-map-container">
          <div className="contact-text-box">
            <h2>Warum Sie uns kontaktieren sollten</h2>
            <p>Unser erfahrenes Team aus Webdesign-Experten, SEO-Spezialisten und Hosting-Profis in Bad Hersfeld hilft Unternehmen, ihre digitale Präsenz nachhaltig zu stärken. Mit modernen Websites, lokaler Suchmaschinenoptimierung (Local SEO) und leistungsstarkem Webhosting sorgen wir dafür, dass Sie in Bad Hersfeld und Umgebung online besser gefunden werden.</p>

            <div className="contact-map" ref={mapContainerRef} style={{ height: "300px", borderRadius: "12px", overflow: "hidden" }}></div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Contact;
